<template>
<div class="side-right-bar">
   <div class="side-bar-nav">
    <div class="side-bar-nav-content">
        <el-popover class="popover-box" placement="right" :offset="30" width="1.5rem"  popper-class="message-popover"  trigger="hover">
          <template #reference>
              <div class="content-view content-phone" @mouseover="isPhoneHover = true" @mouseout="isPhoneHover = false" :class="isPhoneHover ? 'highlightPhone' : 'normalPhone' ">
              </div>
          </template>
          <div  @mouseover="isPhoneHover = true" @mouseout="isPhoneHover = false">
            <div  style="font-size:0.125rem;color: #1978F0;font-weight: 600;">{{phoneTip.tip1}}</div>
            <div style="font-family: PingFangSC-Regular;font-size:.0833rem;color: #393939;font-weight: 400;">{{phoneTip.tip2}}</div>
          </div>
        </el-popover>
        <div class="border-1"></div>
        <el-popover placement="right" :offset="30" width="2rem" popper-class="message-popover" >
          <template #reference>
            <div class="content-view" @mouseover="isEmailHover = true" @mouseout="isEmailHover = false" :class="isEmailHover ? 'highlightEmail' : 'normalEmail' ">
            </div>
          </template>
          <div class=""  @mouseover="isEmailHover = true" @mouseout="isEmailHover = false">
            <div style="font-size:0.13rem; color: #1978F0;font-weight: 600;" class="content-tip">{{emailTip.tip1}}</div>
            <div class="content-tip1" style="font-family: PingFangSC-Regular; font-size:.0833rem; font-weight: 400;"> {{emailTip.tip2}}</div>
          </div>
        </el-popover>
        
        <div class="border-1"></div>
        <el-popover placement="right" :offset="30" width="1.7rem" popper-class="message-popover" trigger="hover">
            <template #reference>
              <div class="content-view" @mouseover="isCodeHover = true" @mouseout="isCodeHover = false" :class="isCodeHover ? 'highlightCode' : 'normalCode' ">
            </div>
            </template>
            <template #default>
               <div  style="display: flex;justify-self: center;text-align: center;"  @mouseover="isCodeHover = true" @mouseout="isCodeHover = false">
                  <img style="width: .7917rem; height: .7917rem;margin: 0 auto;" src="../../assets/home/scan-code@2x.png">
               </div>
                <div style="font-family: PingFangSC-Regular;font-size: .0833rem;color: #393939;font-weight: 400;text-align: center;">扫码关注北京领雁科技公众号</div>
          </template>
        </el-popover>
    </div>
  </div>
  <el-backtop  :right="20" :bottom="100" :visibility-height="700">
    <img style=" width: .6944rem;height:.6944rem;" src="../../assets/home/up-top@2x.png" alt="" srcset=""> 
  </el-backtop>
</div>
 
</template>

<script setup lang="ts">
import { ref } from "vue";
const isPhoneHover  = ref<boolean>(false);
const isEmailHover  = ref<boolean>(false);
const isCodeHover  = ref<boolean>(false);
  
const visible = ref(true)
  const phoneTip = {
    tip1 :'010-52817830',
    tip2 : "工作日 9:30 - 17:30 在线"
  }
  const emailTip = {
    tip1 :'xuyan@leadingtek.com',
    tip2 : "企业邮箱为您提供帮助"
  }

</script>

<style lang="scss" scoped>
  .back-top{
    width: .2778rem;  
    height: .2778rem;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: url('../../assets/home/上滑至顶部@3x.png') center no-repeat;
    background-size: 100% 100%;
    box-shadow: 0px .0139rem .1667rem 0px rgba(67,102,165,0.18);
    line-height: .2778rem;
    color: #1989fa;
    
  } 
       
  .side-bar-nav{
    position: fixed;
    width: .3194rem;
    height: 1.0069rem;
    top: 2.2917rem;
    right: 0.117rem;
    z-index: 300;
    background-color: #fff;
    border-radius:.1667rem ;
    box-shadow: 0rem .0139rem .1667rem 0rem rgba(67,102,165,0.18);
   .side-bar-nav-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .content-view {
        width: .1111rem;
        height: .1111rem;
        margin: .0417rem 0;
        transition: transform 0.3s ease; /* 添加过渡效果 */
      }
      .content-tip-code{
        // width: 100%;
        display: flex;
        justify-self: center;
        text-align: center;
        margin: 0 auto;
        width: .7917rem;
        height: .7917rem;
      }
      .content-tip{
        height: .1736rem;
        font-family: PingFangSC-Semibold;
        font-size: .0833rem;  
        color: #1978F0;
        font-weight: 600;
      }
      .content-tip1{
        width: .9028rem;
        height: .1181rem;
        font-family: PingFangSC-Regular;
        font-size: .0833rem;
        color: #393939;
        font-weight: 400;
        z-index:300;
      }
      .border-1{
        margin:.0417rem 0;
        width: .1111rem;
        height: .0069rem;
        opacity: 0.35;
        border: .0069rem solid rgba(151,151,151,1);
      }
      .highlightPhone{
        margin-top: .1389rem;
        background: url('../../assets/home/phone-light@3x.png') center no-repeat;
        background-size: 100% 100%;
        transform: scale(1.2); /* 鼠标hover时放大1.2倍 */
      }
      .normalPhone{
        margin-top: .1389rem;
        background: url('../../assets/home/iphone-icon@2x.png') center no-repeat;
        background-size: 100% 100%;  
      }
      .highlightEmail{
        background: url('../../assets/home/email-light@3x.png') center no-repeat;
        background-size: 100% 100%;
        transform: scale(1.2); /* 鼠标hover时放大1.2倍 */
      }
      .normalEmail{
        background: url('../../assets/home/email@2x.png') center no-repeat;
        background-size: 100% 100%;
      }
      .normalCode{
        background: url('../../assets/home/code@2x.png') center no-repeat;
        background-size: 100% 100%;
      }
      .highlightCode{
        background: url('../../assets/home/code-light@3x.png') center no-repeat;
        background-size: 100% 100%;
        transform: scale(1.2); /* 鼠标hover时放大1.2倍 */
      }
   }
  }
  :deep(.el-popper)  {
    min-width:0px !important;
    font-size:30px !important;
  }
  :deep(.el-backtop){
    width: .2778rem !important;
    height: .2778rem !important;
  }
  
 
</style>

<style lang="scss">
.message-popover{
  min-width: 0 !important;
}
</style>
